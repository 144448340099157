<template>
  <div class="">
    <div class="card p-2">
      <b-table
        v-if="parties.data && parties.data.length"
        bordered
        hover
        :items="parties.data"
        :fields="fields"
      >
        <template #cell(provider_name)="data">
          <span
            v-if="isComponent && hasPermission('warehouses.parties-show')"
            class="link"
            @click="$emit('openPartyModal', { id: data.item.party_id })"
          >
            {{ data.item.party[userType].name }}
          </span>
          <router-link
            v-else-if="hasPermission('warehouses.parties-show')"
            :to="{
              name: 'warehouseParty-show',
              params: { id: data.item.party_id },
            }"
          >
            {{ data.item.party[userType].name }}
          </router-link>
          <span v-else>
            {{ data.item.party[userType].name }}
          </span>
        </template>
        <template #cell(date)="data">
          {{ dateDotFormat(data.item.party.created_at) }}
        </template>
        <template #cell(comment)="data">
          <p v-if="data.item.imei_1"><b>IMEI 1: </b> {{ data.item.imei_1 }}</p>
          <p v-if="data.item.imei_2"><b>IMEI 2: </b> {{ data.item.imei_2 }}</p>
          {{ data.item.party.comment }}
        </template>
        <template #cell(price)="data">
          <p v-if="data.item.price">
            {{ (data.item.quantity * data.item.price) | formatPrice }} so'm
          </p>
          <p v-if="data.item.price_dollar">
            {{ (data.item.quantity * data.item.price_dollar) | formatPrice }}
            USD
          </p>
        </template>
        <template #cell(cost)="data">
          <p v-if="data.item.cost">
            {{ (data.item.quantity * data.item.cost) | formatPrice }} so'm
          </p>
          <p v-if="data.item.cost_dollar">
            {{ (data.item.quantity * data.item.cost_dollar) | formatPrice }} USD
          </p>
        </template>
        <template #cell(quantity)="data">
          <b v-if="isRemain"> {{ data.item.residual_quantity }}</b>
          <b v-else> {{ data.item.quantity }}</b>
        </template>
        <template #cell(barcode)="data">
          <b> {{ data.item.barcode }}</b>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="data.item.party.status ? 'success' : 'warning'"
            class="mr-1 ml-auto"
          >
            {{ data.item.party.status ? "оплачено" : "не оплачено" }}
          </b-badge>
        </template>
      </b-table>
      <div v-else>Нет полученный товар</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Create",
  components: {},
  props: {
    parties: {
      type: [Array, Object],
      default: () => null,
    },
    userType: {
      type: String,
      default: () => "provider",
    },
    isRemain: {
      type: Boolean,
      default: () => false,
    },
    isComponent: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fields: [
        {
          label: this.$t("Поставщик"),
          key: "provider_name",
        },
        {
          label: this.$t("Дата"),
          key: "date",
        },
        {
          label: this.$t("Комментарий"),
          key: "comment",
        },
        {
          label: this.$t("Количиство"),
          key: "quantity",
        },
        {
          label: this.$t("Цена прихода"),
          key: "cost",
        },
        {
          label: this.$t("Цена продажа"),
          key: "price",
          isAmount: true,
        },
        {
          label: this.$t("Штрих код"),
          key: "barcode",
        },
        {
          label: this.$t("Кто зарегистрирован"),
          key: "party.created_user.name",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions({}),
  },
};
</script>

<style scoped></style>
