<template>
  <div>
    <b-overlay :show="loading">
      <good-table-contracts
        :items="contracts.data"
        :columns="columns"
        :total="contracts.total"
        :page="page"
        :has-show="true"
        model="payment"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => page = p"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import GoodTableContracts from '@/views/table/vue-good-table/GoodTableContracts.vue'

export default {
  name: 'Index',
  components: {
    GoodTableContracts,
  },
  props: {
    contracts: {
      type: [Array, Object],
      default: () => null,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      loading: false,
      statusTab: 1,
      filterModel: {
        per_page: 10,
        number: null,
        status: 1,
        is_overdue: null,
        relations: 'client',
      },
      tabs: [
        { status: 1, name: 'Активные контракты' },
        { status: 2, name: 'Закрытые контракты' },
        { status: 3, name: 'Просроченные контракты' },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Номер договора'),
          field: 'contract_id',
          showField: this.hasPermission('contracts.show'),
        },
        {
          label: this.$t('Количиство'),
          field: 'product_quantity',
        },
        {
          label: this.$t('ФИО'),
          field: 'fullname',
          showField: this.hasPermission('contracts.show'),
        },

        {
          label: this.$t('Телефон'),
          field: 'contract.client.phone',
        },
        {
          label: this.$t('Цена'),
          field: 'product_price_with_percent',
          isAmount: true,
        },
        {
          label: this.$t('Дата'),
          field: 'contract.created_at',
          isDate: true,
        },

        // {
        //   label: this.$t('Просроченная сумма'),
        //   field: 'overdue_amount',
        //   isDangerAmount: true,
        // },
        // {
        //   label: this.$t('Действия'),
        //   field: 'action',
        //   isOrders: true,
        // },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    if (this.isLevier) {
      this.filterModel.status = 1
      this.statusTab = 3
      this.filterModel.is_overdue = true
    }
  },
  methods: {
    ...mapActions({
      getItemsAction: 'contract/index',
      destroyAction: 'region/destroy',
      getCitiesAction: 'city/index',
      destroyCityAction: 'city/destroy',
    }),
    passportField(row) {
      if (row.client && row.client.doc_series) {
        return row.client.doc_series + row.client.doc_number
      }
      return null
    },
    tabChange(status) {
      if (status == 3) {
        this.filterModel.status = 1
        this.filterModel.is_overdue = true
      } else {
        this.filterModel.status = status
        this.filterModel.is_overdue = null
      }
      this.getItems()
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
      this.items = this.contracts
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
